<template>
  <div class="model page">
    <section class="section">
      <h1 class="title">drive</h1>

      <h2>Fetch your design on your Google Drive</h2>

      <p>
        You can fetch a graphic file (jpg, png) on your Google Drive, it will be
        used as a repeatable pattern on the models.
      </p>

      <button
        @click="handleButtonClick"
        :disabled="!pickerApiLoaded"
        class="file-picker"
      >
        Open Google Drive Dialog
      </button>

      <span v-if="model_path_id_latest">
        <p>
          See
          <router-link :to="model_path_id_latest"
            >latest model with this design</router-link
          >.
        </p>
      </span>

      <h2>Make your design and see in an instant the result on a model</h2>

      <p>
        <a href="https://www.google.com/drive/download/" target="_blank"
          >Download</a
        >
        Google Drive for Device and synchronize this file from any device and
        any design software. You can draw from an iPad and visualize the result
        on the models.
      </p>

      <!--file-picker-button
        class="file-picker"
        :config="gConfig"
        @picked="pickerShow"
      >
        Open Google Drive Dialog
      </file-picker-button-->

      <div id="img_preview"></div>
    </section>

    <abstraktion-footer />
  </div>
</template>

<script>
import AbstraktionFooter from '../components/AbstraktionFooter.vue';
//import FilePickerButton from "vue-google-picker";

// https://codesandbox.io/s/4w1xr479q9?file=/src/components/GDriveSelector.vue:537-558
// activate https://console.cloud.google.com/marketplace/product/google/picker.googleapis.com?hl=fr&project=abstraktion-drive&supportedpurview=project

// issue https://github.com/google/google-api-javascript-client/issues/316

export default {
  name: "Profile",
  components: {
    AbstraktionFooter
    //FilePickerButton,
  },
  data() {
    return {
      gConfig: {},
      file_id: null,
      picked: {},
      pickerApiLoaded: false,
      oauthToken: "",
      model_path_id_latest: null,
    };
  },
  mounted() {
    try {
      if (localStorage.getItem("model_texture")) {
        var img = document.createElement("img");

        let texture = localStorage.getItem("model_texture");

        if (texture.length > 500) {
          img.src = texture;
          var preview = document.getElementById("img_preview");
          preview.innerHTML = "";
          preview.appendChild(img);
        }
      }
    } catch {
      console.log("error");
    }

    if (!window.gapi) return console.warn("Google API not loaded");
    window.gapi.load("auth2");
    window.gapi.load("picker", () => {
      this.pickerApiLoaded = true;
    });
  },
  created() {
    // add GOOGLE API => into index.html
    // let gDrive = document.createElement("script");
    // gDrive.setAttribute("type", "text/javascript");
    // gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    // document.head.appendChild(gDrive);

    //process.env.VUE_APP_GOOGLE_DRIVE_API_DEVELOPER_KEY
    //process.env.VUE_APP_GOOGLE_DRIVE_API_CLIENT_ID

    this.gConfig = {
      // The Browser API key obtained from the Google API Console.
      // https://console.cloud.google.com/apis/credentials?project=abstraktion-drive
      developerKey: "AIzaSyD4F8Ff6wj_iBkpHoMIGGeDxOjiyy7JVI0",

      // The Client ID obtained from the Google API Console. Replace with your own Client ID.
      clientId:
        "1025386680170-84v223kac0lf91arc44mcpqj2ed3loun.apps.googleusercontent.com",

      // Scope to use to access user's drive.
      scope: "https://www.googleapis.com/auth/drive.readonly",
      //scope: "https://www.googleapis.com/auth/drive.file",
    };
  },
  methods: {
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      } else {
        return console.warn(authResult.details);
      }
    },
    handleButtonClick() {
      window.gapi.auth2.authorize(
        {
          client_id: this.gConfig.clientId,
          scope: this.gConfig.scope,
        },
        this.handleAuthResult
      );
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        /*eslint no-undef: "off"*/
        const picker = new google.picker.PickerBuilder()
          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.gConfig.developerKey)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    pickerCallback(data) {
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        this.pickerShow(data);
        //this.$emit("picked", data)
      }
    },

    pickerShow(data) {
      //if (data.picked === "picked") {
      let doc = data.docs[0];
      /**
       * store @todo
       */
      this.file_id = doc.id;

      try {
        // this will be used to track file modifications
        localStorage.setItem("model_texture_drive_id", this.file_id);
      } catch {
        console.log("error");
      }

      this.getFile(this.file_id);
      /*} else {
        console.log("error");
      }*/
    },

    test() {
      let client = this.$gapi.getGapiClient();

      client.then((gapi) => {
        // client
        /*******
         * test
         */
        //https://github.com/googleworkspace/browser-samples/blob/master/drive/activity-v2/index.html

        //gapi.auth.getToken().access_token
        //https://www.npmjs.com/package/@maxim_mazurok/gapi.client.driveactivity

        // 403

        gapi.client.load("driveactivity", "v2", () => {
          // now we can use gapi.client.driveactivity
          // ...
          console.log("ok", gapi.client.driveactivity);

          gapi.client.driveactivity.activity
            .query({
              pageSize: 10,
            })
            .then(function (response) {
              console.log("Recent activity:");
              var activities = response.result.activities;

              if (activities && activities.length > 0) {
                for (var i = 0; i < activities.length; i++) {
                  var activity = activities[i];
                  console.log(activity);
                  //var time = console.log(activity);
                  //var action = console.log(activity['primaryActionDetail']);
                  //var actors = activity.actors.map(getActorInfo);
                  //var targets = activity.targets.map(getTargetInfo);
                  // console.log(`${time}: ${truncated(actors)}, ${action}, ` +
                  //           `${truncated(targets)}`);
                }
              } else {
                console.log("No activity.");
              }
            });
        });

        gapi.client.load("drive", "v3", () => {
          /// subscribe

          let cl = gapi.client.drive.changes.watch({
            pageToken: gapi.auth.getToken().access_token,
          });
          window.cl = cl;

          console.log("start");
          console.log(cl);
          gapi.client.drive.changes.getStartPageToken({}, function (err, res) {
            console.log("Start token:", err, res, res.startPageToken);
          });
          console.log("end");

          // var pageToken;
          // // Using the npm module 'async'
          // c.client.drive.changes.list(
          //   {
          //     pageToken: pageToken,
          //     fields: "*",
          //   },
          //   (err, res) => {
          //     if (err) {
          //       console.log(err);
          //       //callback(err);
          //     } else {
          //       // Process changes
          //       res.changes.forEach((change) => {
          //         console.log("Change found for file:", change.fileId);
          //       });
          //       pageToken = res.nextPageToken;
          //       console.log(pageToken);
          //       //callback(res.newStartPageToken);
          //     }
          //   }
          // );
          // console.log("pageToken " + pageToken);
        });
      });
    },

    /**
     * getFile
     * file_id
     */
    getFile(file_id) {
      let client = this.$gapi.getGapiClient();

      client.then((gapi) => {
        // client

        gapi.client.load("drive", "v3", () => {
          /// subscribe

          var file = gapi.client.drive.files.get({ fileId: file_id });

          file.execute(() => {
            // https://stackoverflow.com/questions/37860901/how-to-use-google-drive-api-to-download-files-with-javascript
            //var accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;// or this: gapi.auth.getToken().access_token;

            let access_token = gapi.auth.getToken().access_token;

            try {
              localStorage.setItem("drive_access_token", access_token);
            } catch {
              console.log("error");
            }

            let xhr = new XMLHttpRequest();
            xhr.open(
              "GET",
              "https://www.googleapis.com/drive/v3/files/" +
                file_id +
                "?alt=media",
              true
            );
            xhr.setRequestHeader("Authorization", "Bearer " + access_token);
            xhr.responseType = "arraybuffer";
            xhr.onload = () => {
              //base64ArrayBuffer from https://gist.github.com/jonleighton/958841
              var base64 =
                "data:image/jpg;base64," + this.base64ArrayBuffer(xhr.response);

              try {
                localStorage.setItem("model_texture", base64);
              } catch {
                console.log("error");
              }
              var img = document.createElement("img");

              img.src = base64;
              var preview = document.getElementById("img_preview");
              preview.innerHTML = "";
              //console.log(base64)
              if (base64) preview.appendChild(img);

              // add link to latest model
              try {
                this.model_path_id_latest = localStorage.getItem(
                  "model_path_id_latest"
                );
              } catch {
                console.log("localStorage error");
              }
            };
            xhr.send();
          });
        });
      });
    },

    /**
     * base64ArrayBuffer
     */
    base64ArrayBuffer(arrayBuffer) {
      var base64 = "";
      var encodings =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

      var bytes = new Uint8Array(arrayBuffer);
      var byteLength = bytes.byteLength;
      var byteRemainder = byteLength % 3;
      var mainLength = byteLength - byteRemainder;

      var a, b, c, d;
      var chunk;

      // Main loop deals with bytes in chunks of 3
      for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63; // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
      }

      // Deal with the remaining bytes and padding
      if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + "==";
      } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + "=";
      }

      return base64;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/main.scss";

#img_preview {
  margin: 2rem 0;
}

.section {
  min-height: calc(100vh - 187px);
}
</style>